// src/components/SplashScreen.js
import React, { useState } from 'react';
import { motion } from 'framer-motion';
import logo from '../logo.png';
import './css/SplashScreen.css';

function SplashScreen({ onComplete }) {
    const [showText, setShowText] = useState(false);

    return (
        <div className="splash-screen">
            <motion.img
                src={logo}
                alt="logo"
                className="splash-logo"
                animate={{
                    scale: [1, 2, 2, 1, 1],
                    rotate: [0, 0, 360, 360, 0],
                    borderRadius: ["0%", "0%", "30%", "30%", "0%"]
                }}
                transition={{
                    duration: 2,
                    ease: "easeInOut",
                    times: [0, 0.2, 0.5, 0.8, 1],
                    onComplete: () => {
                        setShowText(true);
                        setTimeout(onComplete, 2000); // Délai avant de passer à la page principale
                    }
                }}
            />
            {showText && (
                <motion.div
                    className="splash-text"
                    initial={{ y: 50, opacity: 0 }}
                    animate={{ y: 0, opacity: 1 }}
                    transition={{ duration: 1, ease: 'easeOut' }}
                >
                    ACore Corporation
                </motion.div>
            )}
        </div>
    );
}

export default SplashScreen;
